import React from 'react';
import { Box } from '@mui/material';

export default function Toggler({ darkMode, handleClick }) {
  return (
    <Box
      fontSize={'1.8rem'}
      sx={{
        cursor: 'pointer',
        ':hover': {
          transform: 'scale(1.3)',
        },
      }}
    >
      {darkMode ? (
        <span onClick={handleClick} aria-label='Sun' role='img'>
          🔆
        </span>
      ) : (
        <span onClick={handleClick} aria-label='New Moon' role='img'>
          🌒
        </span>
      )}
    </Box>
  );
}
