import React from 'react';
import Style from './About.module.scss';
import Terminal from './Terminal';
import { Box } from '@mui/material';
import { info } from '../../info/Info';

export default function About() {
  function aboutMeText() {
    return (
      <>
        <p>
          <span style={{ color: info.baseColor }}>About Me:</span> <br />
          {info.bio}
        </p>
      </>
    );
  }

  function skillsText() {
    return (
      <>
        <p style={{ color: info.baseColor, fontSize: '2rem' }}> Hard Skills:</p>
        <ul className={Style.skills}>
          {info.skills.proficientWith.map((proficiency, index) => (
            <li key={index}> {proficiency}</li>
          ))}
        </ul>

        <p style={{ color: info.baseColor, fontSize: '2rem' }}> Soft Skills:</p>
        <ul className={Style.skills}>
          {' '}
          {info.softSkills.proficientWith.map((proficiency, index) => (
            <li key={index}> {proficiency} </li>
          ))}
        </ul>
      </>
    );
  }

  function miscText() {
    return (
      <>
        <p>
          <span style={{ color: info.baseColor, fontSize: '2rem' }}>
            Hobbies & Interests:
          </span>
        </p>

        <ul className={Style.hobby}>
          {info.hobbies.map((hobby, index) => (
            <li key={index}>
              <Box component={'span'} mr={'1rem'}>
                {hobby.emoji}
              </Box>
              {hobby.label}
            </li>
          ))}
        </ul>
      </>
    );
  }

  return (
    <Box
      maxWidth={'100vw'}
      display={'flex'}
      flexDirection={'column'}
      alignItems={'center'}
      mt={'2rem'}
    >
      <Terminal text={aboutMeText()} />
      <Terminal text={skillsText()} />
      <Terminal text={miscText()} />
    </Box>
  );
}
