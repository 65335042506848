import React from 'react';
import Style from './Terminal.module.scss';
import classNames from 'classnames';
import { Box } from '@mui/material';

const iconClass = 'fa fa-circle';

function Terminal(props) {
  const { text } = props;

  return (
    <Box
      component={'section'}
      className={classNames(Style.terminal, Style.shadowed)}
      width={{ xs: '90%', md: '70%' }}
      borderRadius={'0.5rem'}
      m={'2rem'}
    >
      <Box
        sx={{ backgroundColor: '#1ebccc' }}
        p={'0.4rem'}
        borderRadius={'0.5rem 0.5rem 0 0'}
        fontSize={'1.1rem'}
      >
        <i className={classNames(iconClass, Style.red)} />
        <i className={classNames(iconClass, Style.amber)} />
        <i className={classNames(iconClass, Style.green)} />
      </Box>
      <Box
        py={{ xs: '0.5rem', md: '1.5rem' }}
        px={{ xs: '0.4rem', md: '1rem' }}
        border={'2px solid #1ebccc'}
        borderRadius={'0 0 0.5rem 0.5rem'}
        sx={{ backgroundColor: '#27242f' }}
        fontSize={{ xs: '0.8rem', md: '1.2rem' }}
        lineHeight={{ xs: '1.8rem', md: '2rem' }}
      >
        {text}
      </Box>
    </Box>
  );
}

export default Terminal;
