import self from '../img/self.jpg';
import mock1 from '../img/mock1.png';
import mock2 from '../img/mock2.png';
import mock3 from '../img/mock3.png';
import mock4 from '../img/mock4.png';
import mock5 from '../img/mock5.png';

export let colors = ['#286EB3'];

export const info = {
  firstName: 'Paweł',
  lastName: 'Gnatek',
  initials: 'PG', // the example uses first and last, but feel free to use three or more if you like.
  position: 'Junior Front-End Web Developer',
  selfPortrait: self, //  name your self-portrait in the "img" folder 'self.'!
  gradient: `-webkit-linear-gradient(135deg, ${colors})`, //
  baseColor: colors[0],
  miniBio: [
    {
      emoji: (
        <a
          href='https://drive.google.com/file/d/1IM4MfK7wjoPM1Im2qMHHp6qsX-k7k1xB/view?usp=drive_link'
          target='_blank'
          rel='noreferrer'
        >
          📄
        </a>
      ),
      text: (
        <a
          href='https://drive.google.com/file/d/1IM4MfK7wjoPM1Im2qMHHp6qsX-k7k1xB/view?usp=drive_link'
          target='_blank'
          rel='noreferrer'
        >
          My CV ➡️ download
        </a>
      ),
    },
    // {
    //   emoji: <a href='mailto: pawel.gnatek1@gmail'> 📧</a>,
    //   text: (
    //     <a href='mailto: pawel.gnatek1@gmail'>
    //       pawel.gnatek1@gmail.com ➡️ contact me
    //     </a>
    //   ),
    // },
    // {
    //   emoji: '💼',
    //   text: 'Junior Front-End Developer',
    // },
    {
      emoji: (
        <a
          href='https://goo.gl/maps/ohortfgi4xdbCo398'
          target='_blank'
          rel='noreferrer'
        >
          🌎
        </a>
      ),
      text: (
        <a
          href='https://goo.gl/maps/ohortfgi4xdbCo398'
          target='_blank'
          rel='noreferrer'
        >
          based in London, United Kingdom
        </a>
      ),
    },
  ],

  socials: [
    // {
    //     link: "https://instagram.com",
    //     icon: 'fa fa-instagram',
    //     label: 'instagram'
    // },

    // {
    //     link: "https://facebook.com",
    //     icon: 'fa fa-facebook',
    //     label: 'facebook'
    // },
    {
      link: 'https://github.com/PawelGnatek',
      icon: 'fa fa-github',
      label: 'github',
    },

    {
      link: 'https://www.linkedin.com/in/pawel-gnatek-1b0022285/',
      icon: 'fa fa-linkedin',
      label: 'linkedin',
    },

    {
      link: 'https://twitter.com/Pawel23980024',
      icon: 'fa fa-twitter',
      label: 'twitter',
    },

    // links lead to your social profiles.
  ],

  bio: 'Hi there! My name is Pawel. A Junior Frontend Developer with a passion for creating dynamic and user-friendly web applications. Proficient in JavaScript, React JS, Redux JS, and jQuery, with a deep understanding of HTML, CSS, and SASS. Experienced in package management with NPM and version control using GitHub. Known for writing clean, maintainable code with an eye for detail. Adept at problem-solving, eager to learn new technologies, and committed to delivering high-quality results on time. I look forward to bringing these skills to a dynamic team where I can contribute to creating innovative web solutions.',

  skills: {
    proficientWith: [
      'react.js',
      'redux.js',
      'npm',
      'javascript',
      'jQuery',
      'github',
      'html5',
      'css3',
      'SASS',
    ],
  },
  softSkills: {
    proficientWith: [
      'Problem-solving',
      'Design thinking',
      'Time management',
      'Teamwork',
      'Leadership',
      'Strong communication',
    ],
  },

  hobbies: [
    {
      label: 'finance & investment',
      emoji: '📈',
    },

    {
      label: 'travel',
      emoji: '✈️',
    },
    {
      label: 'football',
      emoji: '⚽',
    },
    {
      label: 'movies',
      emoji: '🎥',
    },
    {
      label: 'reading',
      emoji: '📖',
    },
    {
      label: 'bitcoin',
      emoji: <span style={{ padding: '6px' }}>₿</span>,
    },
  ],
  portfolio: [
    {
      title: 'Money Tracker (ReactJS)',
      live: 'https://money-tracker-868e6.firebaseapp.com/', // link to the live version of your project, netlify, heroku, firebase etc. Or your own domain, if you have it.
      source: 'https://github.com/PawelGnatek/money-tracker', //  link to the **repository** of the project, where the code is hosted.
      image: mock1,
    },
    {
      title: 'Goals List App(ReactJS)',
      live: 'https://goallist-4b66b.firebaseapp.com/',
      source: 'https://github.com/PawelGnatek/goalList',
      image: mock5,
    },
    {
      title: 'Memory Game (HTML, CSS, JS)',
      live: 'https://memory-game-pg.netlify.app/',
      source: 'https://github.com/PawelGnatek/MemoryGame',
      image: mock3,
    },
    {
      title: 'Recipes Book (HTML, CSS, JS, API)',
      live: 'https://recipe-book-pg.netlify.app/',
      source: 'https://github.com/PawelGnatek/RecipeBook',
      image: mock4,
    },
    {
      title: 'Calculator(HTML, CSS, JS)',
      live: 'https://calculator-pg.netlify.app/',
      source: 'https://github.com/PawelGnatek/Calculator',
      image: mock2,
    },
  ],
};
