import React from 'react';
import IconLink from './IconLink';
import { Box } from '@mui/material';

function PortfolioBlock(props) {
  const { image, live, source, title } = props;
  return (
    <Box
      margin={0}
      paddingTop={'10%'}
      display={'flex'}
      flexDirection={'column'}
      justifyContent={'center'}
      alignItems={'center'}
      paddingBottom={'5%'}
      fontSize={'1.3rem'}
      textAlign={'center'}
    >
      <h4>{title}</h4>
      <br></br>
      <Box
        component={'img'}
        src={image}
        alt={'project'}
        borderRadius={'5px'}
        boxShadow={' 0rem 0rem 0.5rem 0.1rem'}
      />
      <Box
        className={'portfolio'}
        display={'flex'}
        flexDirection={'column'}
        gap={'1rem'}
        alignItems={'center'}
        fontSize={{ xs: '1rem', md: '0.8rem' }}
        py={'1rem'}
      >
        <Box
          p={1}
          border={'1px solid '}
          borderRadius={'5px'}
          boxShadow={' 0rem 0rem 0.5rem 0.1rem '}
        >
          <IconLink
            link={live}
            title={'Live Demo'}
            icon={'fa fa-window-restore'}
          />
        </Box>

        <Box
          p={1}
          border={'1px solid'}
          borderRadius={'5px'}
          boxShadow={' 0rem 0rem 0.5rem 0.1rem '}
        >
          <IconLink link={source} title={'Source Code'} icon={'fa fa-code'} />
        </Box>
      </Box>
    </Box>
  );
}

export default PortfolioBlock;
