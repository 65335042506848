import React, { useState } from 'react';
import Style from './Navbar.module.scss';
import Toggler from './home/Toggler';
import { Link, useLocation } from 'react-router-dom';
import { Box } from '@mui/material';
import { info } from '../info/Info';

const links = [
  {
    name: 'Home',
    to: '/',
    active: 'home',
  },
  {
    name: 'Portfolio',
    to: '/portfolio',
    active: 'portfolio',
  },

  {
    name: 'About',
    to: '/about',
    active: 'about',
  },
];

export default function Navbar({ darkMode, handleClick }) {
  const location = useLocation();
  const [active, setActive] = useState(
    location.pathname === '/'
      ? 'home'
      : location.pathname.slice(1, location.pathname.length)
  );

  return (
    <Box
      component={'nav'}
      width={'100vw'}
      justifyContent={'space-between'}
      padding={'1rem'}
    >
      <Box
        component={'ul'}
        display={'flex'}
        justifyContent={'center'}
        alignItems={'start'}
        gap={{ xs: '1rem', md: '4rem' }}
      >
        {links.map((link, index) => (
          <Box
            key={index}
            component={'li'}
            className={link.active === active && !link.type && Style.active}
            sx={{ borderImageSource: info.gradient }}
          >
            <Link
              to={link.to}
              onClick={() => setActive(link.active)}
              className={Style.link}
            >
              {!link.type && <p style={{ padding: '0.1rem 0' }}>{link.name}</p>}
              {link.type && <h1>{link.name}</h1>}
            </Link>
          </Box>
        ))}
      </Box>
      <li
        style={{ display: 'flex-start', float: 'right', marginRight: '1rem' }}
      >
        <Toggler darkMode={darkMode} handleClick={handleClick} />
      </li>
    </Box>
  );
}
