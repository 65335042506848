import React from 'react';
import { Box } from '@mui/material';

function EmojiBullet(props) {
  const { emoji, text } = props;

  return (
    <Box
      component={'li'}
      padding={{ xs: '0.7rem', md: '1rem' }}
      fontSize={{ xs: '1rem', md: '1.5rem' }}
      lineHeight={1}
      style={{ cursor: 'default' }}
    >
      <Box
        component={'span'}
        aria-label='cheese'
        role='img'
        mr={{ xs: '0.5rem', md: '0.8rem' }}
        fontSize={'2rem'}
      >
        {emoji}
      </Box>{' '}
      {text}
    </Box>
  );
}

export default EmojiBullet;
